import React from "react"
import { Link } from "gatsby"

import Layout from "../layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const AdvisoryAgreementPage = () => {
  const pdfUrl = `/Advisory-Agreement.pdf?v=${new Date().getTime()}`
  return (
    <Layout>
      <SEO title="Advisory Agreement" />
      <Container>
        <h2 className="text-center font-size-base">
          ANT MONEY ADVISORS, LLC.
          <br />
          INVESTMENT ADVISORY AGREEMENT
          <br />
          Effective Date: October 21, 2020
        </h2>
        <p>
          This is an agreement between you ("Client") and Ant Money Advisors,
          LLC. (“AMA") an investment advisor registered with the United States
          Securities and Exchange Commission. In consideration of the mutual
          benefits and covenants contained herein, if you agree to the terms of
          this Agreement, please click the button to indicate "I agree". This
          establishes your agreement to the terms hereof and your continued
          participation in the program confirms this Agreement. This contract
          contains a binding arbitration provision, which may be enforced by the
          parties.
        </p>
        <ol className="pl-3">
          <li>
            <p>
              <strong className="text-underline">Advisory Services</strong>.
              Client hereby appoints AMA and AMA hereby accepts the appointment,
              to be Client’s investment advisor and to provide discretionary
              investment management services to the investment advisory
              account(s) (the “Account” whether one or more) established by the
              Client with DriveWealth, LLC. (“DW” or “Custodian”), in accordance
              with the terms and conditions hereinafter set forth. DriveWealth,
              LLC. is a registered broker-dealer with the United States
              Securities and Exchange Commission and a member of the Financial
              Industry Regulatory Authority. Throughout the term of this
              Agreement, AMA shall have full discretion to supervise, manage,
              and direct the assets in the Account, together with all additions,
              substitutions and alterations thereto, with full power and
              authority as agent and attorney-in-fact to purchase, sell, invest,
              reinvest, exchange, convert, and trade the assets in the Account
              in any manner deemed appropriate and to place all orders for the
              purchase and sale of Account assets with or through brokers,
              dealers, or as directed by the Client, as the case may be, all
              without prior consultation with the Client and all at such times
              as AMA deems appropriate (subject to any restrictions imposed by
              the Client). The recommendations developed by AMA are based on the
              professional judgment of AMA’s Chief Investment Officer. AMA shall
              have no responsibility with respect to any Client assets not under
              its control and supervision.
            </p>
            <p>
              In addition to the foregoing, AMA will provide the following
              services:
            </p>
            <ol className="pl-3" type="a">
              <li>
                AMA will determine from time to time which exchange-traded funds
                (“ETF”s) and mutual funds or other securities the Client will
                invest in.
              </li>
              <li>
                AMA will administer the Account, including matters such as: (i)
                regulating the timing of Client’s purchases and sales; (ii)
                allowances of Client’s deposits and withdrawals.
              </li>
              <li>
                AMA will advise Client concerning the investment and
                reinvestment of those assets held in a Client’s Account solely
                through DW.
              </li>
              <li>
                AMA will provide the Client access to their AMA Account
                information via technology provided by third parties (“Third
                Party Technology Providers”). Such technology (“Third Party App”
                or “App”) is solely the technology of that Third-Party
                Technology Provider. The App will provide reporting information
                about goals, progress, Account status, securities positions and
                balances. AMA may also send Client periodic email communications
                describing goals and progress, Account information, and product
                features.
              </li>
              <li>
                AMA reserves the right to cancel or suspend any transaction due
                to fraud or for any other reason, including legal compliance and
                related concerns.
              </li>
            </ol>
            <p>
              AMA’s obligation to provide the above services is subject to
              Client’s compliance with all terms of this Agreement. Nothing in
              this Agreement will give AMA any authority to direct any Client or
              beneficiary cash or securities or to take possession of any assets
              in an Account.{" "}
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Services Outside Scope of Engagement
              </strong>
              . AMA will not provide Client with the following services:
            </p>
            <ol className="pl-3" type="a">
              <li>
                AMA will not provide the Client tax, accounting, or legal
                advice. AMA encourages Client to work closely with Client’s
                attorneys, accountants, and other appropriate professionals.
              </li>
              <li>
                AMA will not act as the custodian for any account. DW shall be
                the custodian for all Client accounts, and DW shall have
                possession of the assets of the accounts or provide custodian
                level statements. The Client will complete the necessary
                application materials to open an account with DW and DW is a
                “qualified custodian” under Rule 206(4)-2 of the Investment
                Advisers Act of 1940, as amended (the “Advisers Act”).
              </li>
              <li>
                AMA will not act as the broker for any account. DW shall be the
                broker/dealer for any account managed by AMA. DW will execute
                all orders on behalf of any account and will provide all
                confirmations to the Client and AMA. AMA reserves the right to
                select a different broker/dealer if AMA believes that a
                different broker/dealer will provide the best execution with
                respect to any transaction. “Best execution” includes the amount
                of broker/dealer fees which will be charged to AMA in connection
                with any trade, but it may also include other benefits.
              </li>
              <li>
                AMA will not act as the bank for any account. Except as
                otherwise provided in this Agreement, bank services are provided
                through a bank chosen by Client.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong className="text-underline">Account Connections</strong>.
            </p>
            <ol className="pl-3" type="a">
              <li>
                The Client is required to connect their Account to the Client’s
                bank and/or Third Party Technology Provider’s account from which
                money will be transferred to fund the Account (the “Funding
                Account”) by entering into the App true, accurate, current, and
                complete information about the Funding Account. The Account will
                not be connected to Client’s Funding Account unless and until a
                confirmation is sent through the App indicating that Client has
                successfully connected the Account to the Funding Account.
              </li>
              <li>
                If a Client’s Funding Account is closed or restricted after it
                is connected to the Account, Client will have no right under the
                Agreement to make deposits to or withdrawals from their Account
                unless: (i) the Funding Account is reopened or unrestricted; or
                (ii) Client successfully connects a different Funding Account to
                the Account.
              </li>
              <li>
                Client will be required to use technology provided by Third
                Party Technology Providers in order to connect and access the
                Account. AMA makes no warranties and expressly disclaims all
                other warranties regarding the correctness, quality, accuracy,
                completeness, reliability or usefulness of such Third-Party
                Technology, or the results that may be obtained from the use of
                the Third Party Technology. Clients are responsible to evaluate
                Third Party Technology, AMA shall not be liable for any damage
                or loss caused by Client’s reliance on or other use of
                Third-Party Technology.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Uniform Gifts to Minors Act (“UGMA”) and Uniform Transfers to
                Minors Act (“UTMA”) Accounts:
              </strong>{" "}
              Client may establish AMA Accounts under UGMA or UTMA regulations
              on behalf of a minor under the age of 18 (“Beneficiary”). With
              respect to each such account:
            </p>
            <ol className="pl-3" type="a">
              <li>
                The law of the Beneficiary’s state of residence will govern the
                UGMA and UTMA accounts. Client will provide AMA the correct
                state of residence of the minor for whom the UGMA or UTMA
                account is created, and represent and warrant to AMA that the
                residence being used to create the account is accurate and
                truthful, and Client will update the information as necessary to
                keep it true, accurate, and current. Client will provide AMA,
                upon request and to the extent possible, with Beneficiary’s
                email address, mailing address, and any other information that
                may assist AMA in contacting the Beneficiary if necessary and
                will update this information as necessary to keep it true,
                accurate and current.
              </li>
              <li>
                The age of custodianship termination varies by state, although
                most states set the maximum age for termination at either 18 or
                21, and certain states permit a custodian to elect to extend
                custodianship to age 25. If the termination age selected by
                Client is different from the default age of the state of
                residence of Beneficiary, Client is responsible under UGMA and
                UTMA for determining the proper termination age. In order to
                select a different age of termination other than the set maximum
                age, Client must contact AMA at{" "}
                <a href="mailto:support@antmoneyadvisors.com">
                  support@antmoneyadvisors.com
                </a>
                . Beneficiary will have complete control over the UGMA or UTMA
                account upon reaching the age of custodianship termination, and
                AMA may restrict the Client’s access to an UGMA or UTMA account
                and register the account’s assets in the Beneficiary’s name upon
                custodianship termination. Questions about termination age
                should be directed to your legal or tax advisor.
              </li>
              <li>
                The Client acknowledges and agrees that as provided under the
                applicable laws governing UGMA/UTMA, (i) all deposits and
                investments into an UTMA/UGMA account immediately and
                irrevocably become property of Beneficiary; (ii) Beneficiary
                will have complete control over the Account when they reach the
                set age of majority; (iii) assets in the Account must be used
                for the benefit of the Beneficiary.
              </li>
              <li>
                The Client has sole responsibility to manage the UGMA or UTMA
                account for Beneficiary, in accordance with all applicable law.
              </li>
              <li>
                The Client agrees to give permission for Beneficiary to
                download, register and access the App in order to access, view,
                and make recommendations (“Beneficiary Requests”) to the
                Account. Client also represents that Beneficiary is eligible to
                access the App’s services. AMA will allow Beneficiaries to
                place, and will generally honor, subject to conditions,
                limitations, and restrictions, Beneficiary Requests so that, by
                choosing among a curated list of buy-and-hold investments that
                AMA has researched and selected, they can begin building
                investing experience at a young age with relatively small
                amounts. Beneficiary Requests are taken into consideration by
                AMA and AMA, in its sole discretion, will use best execution to
                make trades that AMA deems appropriate. AMA may reject
                Beneficiary Requests and/or reverse or offset transactions that
                AMA deems inconsistent with AMA’s investment strategy.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Allocation of Brokerage
              </strong>
              . Where AMA places orders for the execution of portfolio
              transactions for the Account, AMA will obtain the best execution
              for orders of the Account in accordance with applicable law. AMA
              may also allocate such transactions to such brokers and dealers,
              including affiliates of AMA, for execution on such markets, at
              such prices and at such commission rates as in the good faith
              judgment of AMA will be in the best interest of the Client, taking
              into consideration in the selection of such brokers and dealers
              not only the available prices and rates of brokerage commissions,
              but also other relevant factors (such as, without limitation,
              execution capabilities, research and other services provided by
              such brokers or dealers which are expected to enhance the general
              portfolio management capabilities of AMA, and the value of an
              ongoing relationship of AMA) without having to demonstrate that
              such factors are of a direct benefit to the Client.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Client Directed Transactions
              </strong>
              . The Client shall be free to request that AMA choose specific
              securities from a pool of securities that AMA makes available to
              the Client and direct any investment to be held in the Account or
              to withdraw funds held in the Account. The Client agrees to
              request such withdrawals from AMA through the App, when
              applicable, or by reaching out to the App’s customer service and
              such withdrawals will be transferred to the Funding Account. AMA
              will use its best efforts to consider the effects on the Account’s
              investment portfolio and AMA’s investment advice of any
              investments so directed, but AMA assumes no responsibility for any
              investment activity directed by the Client.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Client Representations and Acknowledgements
              </strong>
              . The Client represents and/or acknowledges that: (a) this
              Agreement does not violate any obligations by which the Client is
              otherwise bound and upon execution and delivery, this Agreement
              will be binding upon the Client in accordance with its terms; (b)
              the Client has received{" "}
              <Link to="/form-crs-adv-part-3/">Form CRS</Link>,{" "}
              <Link to="/wrap-fee-brochure-adv-part-2a/">ADV Part 2A</Link>, and{" "}
              <Link to="/brochure-supplement-adv-part-2b/">Part 2B</Link>, as
              filed with the Securities and Exchange Commission; (c) the Client
              has provided via the Website or App all of the information which
              AMA may require or reasonably request in order to perform its
              duties hereunder without violating or causing any violation of its
              fiduciary duties hereunder, or any provision of any applicable
              law, and promptly will notify AMA, through the Website or App, of
              any change in the information.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                AMA’S Representations and Acknowledgements
              </strong>
              . AMA represents and/or acknowledges that: (a) AMA is registered
              as an investment adviser with the United States Securities and
              Exchange Commission; (b) AMA has delivered to the Client{" "}
              <Link to="/form-crs-adv-part-3/">Form CRS</Link>,{" "}
              <Link to="/wrap-fee-brochure-adv-part-2a/">ADV Part 2A</Link>, and{" "}
              <Link to="/brochure-supplement-adv-part-2b/">Part 2B</Link>, as
              filed with the Securities and Exchange Commission; and (c) to the
              extent required by law, AMA will treat as confidential any
              information obtained from or about the Client or the Client’s
              Account through the performance of its obligations under this
              Agreement.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">Agency Relationship</strong>.
              This Agreement is an agency agreement and does not pass title to
              any Account assets to AMA. AMA does not assume responsibility for
              the payment of any obligation of the Account or of the Client
              absent written acknowledgement by the Client. AMA may execute, as
              agent, any declaration or certificates of ownership required under
              any state or federal tax law.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">Voting of Proxies</strong>. AMA
              shall have no obligation to save and to hold for Client, or to
              forward to Client, any proxies, notices of shareholders’ meetings,
              annual reports or other literature customarily mailed to
              shareholders. AMA will not be required to take any action or
              render any advice with respect to voting of proxies solicited by,
              or with respect to, the issuers of securities in which Client’s
              assets may be invested. However, Custodian may forward to the
              Client any proxies, notices of shareholders meetings, annual
              reports or other literature customarily mailed to shareholders,
              when applicable. Client will be responsible for voting all proxies
              received in connection with assets held in the Account.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">Non-exclusivity</strong>. It is
              understood that AMA performs investment advisory services for
              various clients. The Client agrees that AMA may give advice and
              take action in the performance of its duties with respect to any
              of its other Clients which may differ with respect to the Account.
              Moreover, it is understood that AMA and its principals may take
              actions in the purchase or sale of securities which may differ
              from the actions AMA takes in connection with the Client Account.
              Nothing in this Agreement shall be deemed to confer upon AMA any
              obligation to acquire for the Account a position in any security
              which AMA or its directors, principals or employees may acquire
              for their own accounts or for the account of any other Client.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Limitation of Liability
              </strong>
              . In providing services under this Agreement, the Client
              acknowledges that except for malfeasance or violation of
              applicable law, neither AMA, nor its principals, directors,
              officers, employees or agents shall be liable for any damages,
              losses, expenses, or costs (including without limitation any
              attorneys’ fees) (collectively a “Loss”) arising out of or in
              connection with any acts or omissions or for any errors of
              judgment or use of discretion in managing the Account or for any
              Loss incurred by reason of any acts or omissions of any broker,
              custodian or other third party providing services, directly or
              indirectly, to the Account. The Client agrees to hold harmless and
              indemnify AMA and its principals, directors, officers, employees
              or agents against any Loss which AMA may incur if and to the
              extent such Loss is caused by the Client’s or its agent’s or
              designee’s (other than AMA) own actions or omissions or by any
              inaccuracy or breach by the Client of any of its representations
              or acknowledgements hereunder. The Client understands that federal
              and/or state securities laws give rights to the Client that may
              not be waived by this Agreement. This Section 11 shall survive the
              termination of this Agreement.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">Account Reporting</strong>. By
              accepting this Agreement, the Client is instructing the Custodian
              to provide AMA with such periodic Account reports as AMA may
              reasonably request from time to time. The Custodian agrees to
              deliver via email, the Website, or App a quarterly account
              statement directly to the client showing all disbursements from
              the account. The Client recognizes that dividends, capital gains,
              transfers and sales of securities may create a taxable event
              unless the Account is a tax-qualified or tax-exempt account. The
              Client also acknowledges that AMA does not offer legal or tax
              advice and it is the separate responsibility of the Client to
              retain the services of legal and tax professionals to the extent
              deemed necessary.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Termination, Assignment and Amendment
              </strong>
              . The Client may terminate this Agreement without penalty, if
              notice is given by written request within five business days after
              entering into the Agreement. Otherwise costs incurred to the date
              notice is received will be retained.
            </p>
            <p>
              The Client acknowledges and understands that, unless otherwise
              agreed, this Agreement may be terminated by either party to this
              Agreement at any time upon thirty (30) days’ prior written notice
              to the other party. No assignment (as defined in the Investment
              Advisers Act of 1940, as amended) of this Agreement by AMA shall
              be effective without the Client’s consent. Unless otherwise
              provided herein, any amendment of this Agreement shall require the
              written consent of both parties.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">Governing Law</strong>. The
              Client understands that unless preempted by federal law, this
              Agreement shall be governed by the laws of the State of California
              without giving effect to any conflict of laws, principle, doctrine
              or statute.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">Notices</strong>. The Client
              understands that unless subsequent written notice is given, any
              notice, report or other written communication provided to a party
              shall be mailed to such party’s address as stated on the signature
              page of this Agreement (or any exhibit thereof), and that AMA may
              forward to its agents a copy of any written communication sent by
              the Client in connection with this Agreement. All notices and
              other communications contemplated by this Agreement shall be
              deemed duly given if delivered in accordance with this Section 15.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">Risk</strong>. Client hereby
              represents and warrants that Client understands that investments
              in securities are inherently risky, and that the risks of such
              investments increase in proportion to the potential for return
              from such investments. Client understands that AMA is acting in
              good faith, but that AMA is not a guarantor of the performance of
              any security purchased or sold in the account and that AMA shall
              not be liable for any act or omission in connection with this
              Agreement by the Client, except in the case of AMA's malfeasance,
              misconduct, or willful violation of applicable law. Client
              understands and accepts that a security’s past performance may not
              be indicative of its future results.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Client Rights and Obligations
              </strong>
              .
            </p>
            <ol className="pl-3" type="a">
              <li>
                The Client retains sole ownership of its Account, and has the
                sole right to withdraw securities or cash, exercise or delegate
                proxy voting, and receive transaction confirmations. The Client
                may make deposits and withdrawals, subject to UTMA and UGMA
                regulations, Third Party Technology Provider’s requirements and
                any maintenance requirements of the Custodian account.
              </li>
              <li>
                Client acknowledges that AMA will not provide investment advice
                in person, over the phone, or through any other medium, other
                than in the Client Account via the App.
              </li>
              <li>
                Client is liable, as provided in this subsection, for any
                unauthorized electronic fund transfer involving Client’s
                Account. Client’s liability for an unauthorized electronic fund
                transfer shall be determined as follows:
                <ol className="pl-3" type="1">
                  <li>
                    Timely notice given. If the Client notifies AMA within two
                    business days after learning of the loss or theft of the
                    access device, the Client's liability shall not exceed the
                    lesser of $50.00 or the amount of unauthorized transfers
                    that occur before notice to AMA.
                  </li>
                  <li>
                    Timely notice not given. If the Client fails to notify AMA
                    within two business days after learning of the loss or theft
                    of the access device, the Client's liability shall not
                    exceed the lesser of $500 or the sum of: (i) $50.00 or the
                    amount of unauthorized transfers that occur within the two
                    business days, whichever is less; and (ii) The amount of
                    unauthorized transfers that occur after the close of two
                    business days and before notice to the institution, provided
                    the institution establishes that these transfers would not
                    have occurred had the Client notified the institution within
                    that two-day period.
                  </li>
                  <li>
                    Periodic statement. Timely notice not given. A Client must
                    report an unauthorized electronic fund transfer that appears
                    on a periodic statement within 60 days of AMA's transmittal
                    of the statement to avoid liability for subsequent
                    transfers. If the Client fails to do so, the Client's
                    liability shall not exceed the amount of the unauthorized
                    transfers that occur after the close of the 60 days and
                    before notice to the institution, and that the institution
                    establishes would not have occurred had the Client notified
                    the institution within the 60-day period.
                  </li>
                  <li>
                    Extension of time limits. If the Client's delay in notifying
                    AMA was due to extenuating circumstances, AMA in its own
                    discretion may extend the times specified above to a
                    reasonable period.
                  </li>
                  <li>
                    Notice to AMA. (i) Notice to AMA is given when a Client
                    takes steps reasonably necessary to provide AMA with the
                    pertinent information, whether or not a particular employee
                    or agent of AMA actually receives the information. (ii) The
                    Client may notify AMA by email or in writing. (iii) Written
                    notice is considered given once AMA has received the notice
                    by any usual means. Notice may be considered constructively
                    given when AMA becomes aware of circumstances leading to the
                    reasonable belief that an unauthorized transfer to or from
                    the Client's account has been or may be made.
                  </li>
                  <li>
                    If state or federal law or an agreement between the Client
                    and AMA imposes less liability for Client than is provided
                    by this section, the Client's liability shall not exceed the
                    amount imposed under state or federal law or the Agreement.
                  </li>
                  <li>
                    As used herein, “business day” means any day except any
                    Saturday, any Sunday, and any day which is a federal legal
                    holiday in the United States.{" "}
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Electronic Fund Transfer Disclosure Statement
              </strong>
              .
            </p>
            <p>
              The following disclosures are made in accordance with the federal
              law regarding electronic payments, deposits, transfers of funds
              and other electronic transfers to and from your account(s). There
              may be limitations on account activity that restrict your ability
              to make electronic fund transfers. Any such limits are disclosed
              in the appropriate agreements governing your account.{" "}
            </p>
            <ol className="pl-3" type="a">
              <li>
                Definitions: <br />
                Electronic Fund Transfer: Any transfer of funds, other than a
                transaction originated by check, draft or similar paper
                instrument, that is initiated through an electronic device or
                computer to instruct us to debit or credit an account.
                Electronic Fund Transfers include such electronic transactions
                as direct deposits or withdrawals of funds, transfers initiated
                via email, website or mobile application. Preauthorized
                Electronic Fund Transfer: An Electronic Fund Transfer that you
                have authorized in advance to recur at substantially regular
                intervals; for example, direct deposits into or withdrawal of
                funds out of your account.
              </li>
              <li>
                Your Liability: Authorized Transfers: You are liable for all
                Electronic Fund Transfers that you authorize, whether directly
                or indirectly. Unauthorized Transfers: Tell us at once if you
                believe your account or PIN or Access Information (as defined
                below) is lost or stolen or has been or may be subject to
                unauthorized Electronic Fund Transfers. Support message us
                immediately to keep your possible losses to a minimum. You could
                lose all the money in your account(s). If you tell us within two
                (2) business days after learning of the loss or theft of your
                account access device, or after learning of any other
                unauthorized transfers from your account, you can lose no more
                than $50.00 if Electronic Fund Transfers are made without your
                permission. For these transactions, if you DO NOT tell us within
                two (2) business days after learning of the loss, theft or
                unauthorized use, and we can establish that we could have
                prevented the unauthorized transfer(s) if you had told us in
                time, you could lose as much as $500.00. Also, if your periodic
                account statement shows unauthorized transfers and you DO NOT
                tell us within sixty (60) days after the statement was delivered
                to you, you may not get back any money you lose after the sixty
                (60) day period if we can prove that we could have prevented the
                unauthorized transfer(s) if you had told us in time. If an
                extenuating circumstance (such as extended travel or
                hospitalization) prevents you from promptly notifying us of a
                suspected lost or stolen access device or of any other suspected
                unauthorized transfer(s), the time periods specified in this
                Section B may be extended for a reasonable period.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong className="text-underline">Client Communications</strong>.
              Client recognizes that the value and usefulness of the services
              provided by AMA will be dependent upon information provided by
              Client through the App. Client represents and warrants that the
              financial and other information provided to AMA is true, correct,
              and complete to the best of Client’s knowledge. Client authorizes
              AMA to rely solely on the information provided by Client through
              the App, and agrees that AMA is not required to independently
              verify any information obtained from Client or Client’s
              representatives. Client agrees to promptly inform AMA of any
              changes in Client’s or Beneficiary’s information, including
              financial condition, investment objectives, contact information,
              or any other material facts. Client authorizes AMA to provide
              personal financial information, quarterly reviews and reports,
              newsletters, disclosure documents, and similar information, and
              other communications, notices and required disclosures to Client
              via e-mail and the App.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">Fees</strong>. AMA provides an
              Investment Wrap Fee Program free of charge for all the services
              provided in this Agreement. Client understands, however, that it
              will be responsible for any fees charged by Client's custodian
              and/or broker-dealer, and for all taxes owed with respect to the
              Account holdings and transactions. Mutual funds and ETFs that are
              purchased in the account may charge separate advisory fees and
              other expenses, as set forth in each securities’ prospectus.
            </p>
          </li>
          <li>
            <p>
              <strong className="text-underline">
                Additional Terms and Conditions
              </strong>
              .
            </p>
            <ol className="pl-3" type="a">
              <li>
                <strong>Use of Application:</strong> Use of AMA’s services and
                tools are subject to separate Terms of Service agreements, which
                may be amended from time to time. Client understands that
                personal information, including that which is used to create an
                investment profile, is collected by electronic means. Client
                will be authenticated with a username and password and Client is
                responsible for selecting a safe password and not sharing it
                with others. Unauthorized access to account(s) may result in
                unintended changes to the accounts, investment profiles, or
                other instructions provided to AMA and AMA accepts no liability
                for actions resulting from such unauthorized access as further
                detailed in the Terms of Service.
              </li>
              <li>
                <strong>Market Risk:</strong> Client acknowledges that
                investments are subject to various market, currency, economic,
                political, and business risks, and that investments will not
                always be profitable. In fact, losses may be incurred in
                connection with the purchase and sale of securities. Client
                understands that AMA does not guarantee the success or
                performance of any particular investment, investment strategy,
                or recommendation (a) made by AMA; or (b) the services.
              </li>
              <li>
                <strong>Limitation of Liability:</strong> Except as may
                otherwise be provided by law, AMA will not be liable to Client
                for (a) any loss that Client may suffer by reason of any
                recommendations made, or other action taken or omitted, by AMA
                in good faith and with that degree of care, skill, prudence, and
                diligence under the circumstances that a prudent person acting
                in a fiduciary capacity would use; (b) any loss arising from
                AMA’s adherence to Client’s instructions; (c) any loss arising
                from any investment that Client holds or makes that AMA does not
                recommend; (d) any act or failure to act by Client’s custodian,
                broker-dealer or other third party; (e) any loss arising from
                Client’s failure to follow AMA’s investment advice; (f) any loss
                resulting from Client’s failure to provide AMA with current,
                updated, and accurate information related to the Investment
                Profile and Plan; or (g) any loss caused by conditions and
                events beyond AMA’s control including, without limitation:
                electrical, mechanical or equipment breakdowns, computer system
                failures and malfunctions, system access issues, system capacity
                issues, delays by third party vendors and/or communications
                carriers. You agree that in no event will the total aggregate
                liability of AMA for any claims, losses, or damages arising
                under or relating to this Agreement or AMA’s activities under
                this Agreement, whether in contract or tort, including
                negligence, exceed the total prior twelve months’ fees paid by
                the Client under the Account Agreements, even if AMA has been
                advised of the possibility of a larger claim, loss, or damage;
                provided, however, that such limitation shall not apply to the
                Client if a greater recovery is required pursuant to
                non-waivable provisions of applicable Federal and state
                securities laws. Furthermore, Federal and state securities laws
                impose liabilities under certain circumstances on persons who
                act in good faith, and therefore nothing in this Agreement will
                waive or limit any rights that Client may have under those laws.
              </li>
              <li>
                <strong>Voting Securities and Related Matters:</strong> AMA
                shall have no authority or power to exercise voting rights
                (including with respect to voting proxies), rights to consent to
                corporate actions, or other similar rights with respect to
                securities held in an Account. Rather, Client shall retain all
                such authority. Further, AMA will not take any action or render
                any advice with respect to any legal actions, including but not
                limited to class action lawsuits, involving securities held in
                Client’s account(s).
              </li>
              <li>
                <strong>Disclosure:</strong> Client acknowledges receipt of a
                copy of AMA’s most recent Privacy Policy,{" "}
                <Link to="/form-crs-adv-part-3/">Form CRS</Link> and Form ADV{" "}
                <Link to="/wrap-fee-brochure-adv-part-2a/">Part 2A</Link> and{" "}
                <Link to="/brochure-supplement-adv-part-2b/">Part 2B</Link>.
                Client represents that Client has reviewed and considered the
                disclosures made by AMA in this Agreement, in AMA’s Form CRS,
                and Form ADV, Part 2, including, in particular, the portions
                related to services, compensation, risks, and potential
                conflicts of interest, as well as the remainder of the
                disclosures concerning, among other matters, background
                information such as educational and business history, business
                practices such as the types of advisory services provided, the
                methods of securities analysis used, and the like.
              </li>
              <li>
                <strong>No Services to Non-U.S. Persons:</strong> Client
                represents and warrants that both the Client and Beneficiary are
                U.S. citizens, U.S. permanent residents, or have a valid U.S.
                visa and reside in the United States. AMA makes no
                representations or warranties regarding its compliance with laws
                or legal requirements of any non-U.S. jurisdiction. The services
                offered hereby are not being offered to, and are generally not
                available to, anyone located outside the United States,
                including U.S. citizens residing abroad.
              </li>
              <li>
                <strong>Access Interruptions:</strong> AMA makes no guarantees
                that access to the App will be available at all times. Client
                understands that AMA may at times suspend access to the App,
                without notice to Client, in order to make repairs or upgrades.
                In addition, access to the App may at times be limited or
                unavailable due to hardware or software malfunctions, technical
                issues, peak demand, maintenance, upgrades, failure or
                interruption of internet service, acts of God, or for other
                reasons.
              </li>
              <li>
                <strong>Notices and Communications:</strong> Except where a
                different form of notice is specifically provided for in this
                Agreement, any notices required or permitted under this
                Agreement shall be in writing and shall be sufficient in all
                respects if (i) sent through the App, (ii) sent by electronic
                mail to the Client at the address provided to AMA by Client, or
                (iii) sent by mail or overnight delivery.
              </li>
              <li>
                <strong>Entire Agreement:</strong> This Agreement constitutes
                the entire agreement between the parties and each party hereto
                acknowledges to the other that it is not relying on any
                statement, understanding or agreement not fully stated herein
                and each party hereby expressly waives any, and all, claims or
                defenses to enforcement of this Agreement which are based on any
                statement, understanding or agreement not fully stated herein.
                This Agreement supersedes and replaces any prior agreement
                between the parties.
              </li>
              <li>
                <strong>Amendment and Waiver:</strong> Except as otherwise set
                forth in this Agreement, any amendment to this Agreement must be
                in writing and agreed upon by the parties to be valid. The
                failure of either party to insist, in any one or more instances,
                upon performance of the terms or conditions of this Agreement
                shall not be construed as a waiver or a relinquishment of any
                right granted hereunder or of the future performance of any such
                term, covenant or condition.
              </li>
              <li>
                <strong>Electronic Signature:</strong> Client’s intentional
                action of electing to agree to this Agreement is valid evidence
                of Client’s consent and intention to be legally bound by this
                Agreement and any other documentation submitted in the process
                or governing Client’s relationship with AMA. The electronically
                stored copy of this Agreement shall be considered the complete,
                authentic, and enforceable record of the Agreement, admissible
                in judicial or administrative proceedings. If Client and
                Client’s spouse have electronically agreed to this Agreement as
                "Client", the representations under this Agreement with respect
                to Client will be joint and several.
              </li>
              <li>
                <strong>Additional Client Representations:</strong> Client
                represents and warrants that Client has the full power and
                authority to execute, deliver, and perform Client’s and
                Beneficiary’s obligations under this Agreement. Client further
                warrants that this Agreement is enforceable against Client in
                accordance with its terms, and that the terms of this Agreement
                do not violate any obligation by which the Client is bound. If
                Client is a corporation, limited liability company, partnership,
                trust, or other legal entity, Client represents and warrants
                that the individual executing this Agreement on behalf of Client
                has been duly authorized to do so.
              </li>
              <li>
                <strong>Arbitration:</strong> If you have a dispute with AMA, we
                will attempt to resolve any such disputes through our support
                team. If we cannot resolve the dispute through our support team,
                you and we agree that any dispute arising under these terms of
                service shall be finally settled in binding arbitration, on an
                individual basis, pursuant to this clause. The parties waive
                their rights to seek remedies in court, including any right to a
                jury trial. The parties agree that any dispute between or among
                any of the parties arising out of, relating to, or in connection
                with this Agreement or the Account(s), including the
                determination of the scope and applicability of the agreement to
                arbitrate, shall be resolved exclusively through binding
                arbitration conducted under the auspices of the American
                Arbitration Association ("AAA") in accordance with AAA rules for
                arbitration of consumer disputes or commercial disputes, as
                applicable. You and AMA expressly waive any right to participate
                in a class action lawsuit or class-wide arbitration. The
                arbitration will be conducted by a single, neutral arbitrator
                and shall take place in Orange County, California in the English
                language. The arbitrator may award any relief that a court of
                competent jurisdiction could award, and the arbitral decision
                may be enforced in any court. The prevailing party in any
                arbitration or subsequent action or proceeding under these terms
                of service shall be entitled to costs and attorneys’ fees. This
                section shall survive the termination of this agreement.
              </li>
            </ol>
          </li>
        </ol>
        <hr />
        <p>
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            Click here
          </a>{" "}
          to download the Advisory Agreement document.
        </p>
      </Container>
    </Layout>
  )
}

export default AdvisoryAgreementPage
